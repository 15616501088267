@font-face {
    font-family: "KeepCalmMedium";
    src: local("KeepCalmMedium"),
    url("./KeepCalmMedium.ttf") format("truetype");
    font-weight: normal;
}

.App-logo {
    height: 30vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}


body {
  background-color: #1F1A2C;
}


.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .App-header {
    min-height: 70vh;
  }
}



.App-footer {
    font-size: calc(10px + 2vmin);
    text-align: left;
    font-family: "KeepCalmMedium";
    line-height: 0px;
    position: absolute;
    bottom: 4vmin;
    left: 6vmin;
}
.App-text-soon { 
  color: #AE8E98;
}
.App-text-nuts { 
  color: #F2DBCB;
}

